// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-graphics-tsx": () => import("./../src/pages/graphics.tsx" /* webpackChunkName: "component---src-pages-graphics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-salary-privacy-tsx": () => import("./../src/pages/salary-privacy.tsx" /* webpackChunkName: "component---src-pages-salary-privacy-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */)
}

